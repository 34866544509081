import React from "react"
import { navigate } from "gatsby"
import { Carousel } from "react-bootstrap"
import Boton from "../components/Boton"

import Comercial from "../images/comercial.png"
import Industrial from "../images/industrial.png"
import Residencial from "../images/residencial.png"
import Albercas from "../images/albercas.png"

import "./carousel.scss"

const Slide = ({ title, text1, text2, text3, img }) => {
  return (
    <div className="flex flex-col sm:flex-row">
      <div className="sm:w-1/3 sm:order-2">
        <img
          src={img}
          alt="Ikaro"
          className="rounded-full w-40 h-40 sm:w-56 sm:h-56 bg-pinkbrand mx-auto my-4"
          data-sal="zoom-out"
          data-sal-duration="1000"
        />
      </div>
      <div className="sm:w-2/3 sm:order-1">
        <p className="text-lg md:text-xl md:text-2xl font-bold">{title}</p>
        <p className="text-xl md:text-3xl md:text-4xl mt-8">
          {text1}
          <br />{text2}
          <span className="font-bold">{" "}{text3}</span>
        </p>
      </div>
    </div>
  )
}

const SectionD = () => {
  return (
    <div className="pt-12 sm:pt-0">
      <div className="min-h-screen text-white flex items-center justify-center relative flex-col">
        <div className=" mt-12 sm:mt-20 container text-blue2 ">
          <p className="mb-2 text-large font-bold text-pinkbrand">Casos de éxito</p>
          <Carousel className="carousel2" controls={false}>
            <Carousel.Item>
              <Slide
                title="Residencial"
                text1="Casa de campo"
                text2="Se evitó la emisión de 3 toneladas de CO2 al año, equivalente a 1.5 hectáreas de bosque en un año."
                text3="El recibo de luz disminuyó un 99%."
                img={Residencial}
              />
            </Carousel.Item>
            <Carousel.Item>
              <Slide
                title="Comercial"
                text1="Oficinas"
                text2="Se evitó la emisión de 29.5 toneladas de CO2 al año, equivalente a 14.6 hectáreas de bosque en un año."
                text3="El recibo de luz disminuyó un 89%."
                img={Comercial}
              />
            </Carousel.Item>
            <Carousel.Item>
              <Slide
                title="Industrial"
                text1="Supermercado"
                text2="Se evitó la emisión de 295.9 toneladas de CO2 al año, equivalente a 144 hectáreas de bosque en un año."
                text3="El recibo de luz disminuyó un 25%."
                img={Industrial}
              />
            </Carousel.Item>
            <Carousel.Item>
              <Slide
                title="Albercas"
                text1="Casa de fin de semana"
                text2="Se evitó la emisión de 6 toneladas de CO2 al año, equivalente a 3 hectáreas de bosque en un año."
                text3="El recibo de luz disminuyó un 99%."
                img={Albercas}
              />
            </Carousel.Item>
          </Carousel>
          <div className="flex justify-center">
            <p
              className="inline-flex text-left font-light text-lg md:text-xl"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="1000"
            >
              Utilizar energía solar contribuye a cuidar el planeta.
            </p>
          </div>
          <div className="text-center mt-6 mb-4">
            <Boton target="contact">Contáctanos</Boton>
          </div>
        </div>
        <div className=" mt-auto bg-blue2 pt-4 pb-6 w-full text-blue flex justify-center">
          <div className="container text-white">
            <div className="sm:grid sm:grid-cols-3">
              <p className="text-sm sm:text-base font-medium mb-2 sm:mb-0">
                © Ikaro 2022. Todos los derechos reservados.
              </p>
              <p className=" font-medium text-sm sm:text-base sm:text-center mb-2 sm:mb-0">
                <span className="cursor-pointer" onClick={() => navigate("/aviso")}>
                  Aviso de privacidad
                </span>
              </p>
              <p className=" font-medium text-sm sm:text-base sm:text-right">
                Diseño y desarrollo web por{" "}
                <a
                  href="https://www.trazovivo.com"
                  target="_blank"
                  className="font-display max-w-sm leading-tight"
                  rel="noreferrer"
                >
                  <span className="text-white link link-underline link-underline-white ">
                    Trazo Vivo
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionD
