import React from "react"
import { Carousel } from "react-bootstrap"

import Boton from "../components/Boton"

import Path1 from "../images/path1.svg"
import Path2 from "../images/path2.svg"
import Path3 from "../images/path3.svg"

import "./carousel.scss"

const Slide = ({ title, text1, text2, img }) => {
  return (
    <div className="flex flex-col sm:flex-row sm:justify-between">
    <div className="w-32 h-32 md:w-48 md:h-48 bg-white rounded-full align-center justify-center items-center flex sm:mr-4 sm:order-2 m-auto">
      <img src={img} alt="Ikaro" className="w-20 md:w-32" />
    </div>
      <div className="sm:w-2/3 order-1">
        <p className="text-lg md:text-xl md:text-2xl font-bold">{title}</p>
        <p className="text-2xl md:text-3xl md:text-4xl mt-8">
          <span className="font-bold">{text1}</span> {text2}
        </p>
      </div>
    </div>
  )
}

const SectionB = () => {
  return (
    <div className="mt-20 pb-12 sm:pt-0 sm:pb-0">
      <div className="relative flex-col flex justify-center">
        <Carousel controls={false}>
          <Carousel.Item>
            <Slide
              title="Transforma"
              text1="La energía es una fuerza que siempre se transforma y nunca se
                  desvanece."
              text2="Las células fotovoltaica convierten la luz solar en
                  electricidad."
              img={Path1}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Slide
              title="Ahorra"
              text1="Además de contribuir con el cuidado del planeta al reducir las
                  emisiones de CO2 por la generación de energía,"
              text2="tu tarifa eléctrica puede verse reducida hasta en un 96%."
              img={Path2}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Slide
              title="Almacena"
              text1="La energía se almacena en las baterías y en caso de un apagón
                  la tendrás disponible en cualquier momento."
              text2="(Las baterias son opcionales pero no son necesarias)."
              img={Path3}
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <p
        className="font-bold text-2xl text-center"
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-duration="1000"
      >
        Comienza a utilizar energía solar y contribuye a cuidar el planeta.
      </p>
      <div className="text-center mt-6 mb-4">
        <Boton target="contact">Contáctanos</Boton>
      </div>
    </div>
  )
}

export default SectionB
