import React from "react"

import Boton from "../components/Boton"

import Patron2 from "../images/Patron2.svg"

const SectionC = () => {
  return (
    <div className="text-blue2">
      <div className="flex mt-20 mb-4">
        <p
          className="inline-flex text-left font-light text-3xl sm:text-4xl md:text-5xl"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-duration="1000"
        >
          El mundo necesita de manera urgente
          <br className="hidden lg:block" /> que las actividades humanas sean{" "}
          <br className="hidden lg:block" />
          descarbonizadas.
        </p>
      </div>
      <div className="flex flex-col sm:flex-row sm:gap-12">
        <div className="sm:w-1/3 flex justify-center items-center sm:order-2">
          <div className="">
            <img src={Patron2} className="w-28 sm:w-48 m-auto" alt="Ikaro" />
          </div>
        </div>
        <div className="sm:w-2/3 text-center sm:order-1 text-lg font-light mt-4">
          <p className=" text-left mb-4">En Ikaro nos apasiona la sustentabilidad.</p>
          <p className=" text-left mb-4">
            Instalando sistemas solares fotovoltaicos apoyamos a nuestros
            clientes a reducir su huella de carbono.
          </p>
          <p className=" text-left mb-4">
            Nuestra meta es que nuestros clientes ahorren en su gasto energético
            usando tencologías limpias y confiables.
          </p>
          <p className=" text-left mb-4">
            Más de 20 años de experiencia, más de 100 sistemas solares
            instalados y un equipo multidisciplinario nos respaldan.
          </p>
        </div>
      </div>
      <div className="mt-10 mb-10 flex justify-center">
        <Boton target="/conocenos" direction="left">
          Conócenos
        </Boton>
      </div>
    </div>
  )
}

export default SectionC
